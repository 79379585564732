import api from "@/common/services/api";

export default new class {
    baseUrl = "/commissions";
    
    getSelectData(data) {
        return api.instance.get("/get-select-data", {params: data});
    }

    getCommissions(data) {
        return api.instance.get(this.baseUrl, {params: data});
    }

    getCommission(id) {
        return api.instance.get(this.baseUrl + '/' +id);
    }

    changeCommissionPayment(id) {
        return api.instance.post('commission/enable-disable-payment/' +id);
    }

    export(data) {
        data['export'] = true;
        return api.instance.get(this.baseUrl,{responseType: 'blob',params: data});
    }
};