<app-header menu="no">
    <a href="#" @click="$router.go(-1)" class="header-icon header-icon-1">
        <i class="fa fa-arrow-left"></i>
    </a>
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">{{commission.country.currency}} {{
        commission.commission_amount }}</h1>
</app-header>
<app-footer />
<div class="page-title-clear"></div>

<div class="page-content">
    <div class="d-flex justify-content-end align-items-center pb-2 ms-4 me-4"
        v-if="hasPermission('commission_disable')">
        <p class="color-theme font-13 font-500 m-0 letterSpacing">Commission Enable/Disable</p>
        <div data-trigger-switch="commission-toggle" aria-expanded="false" aria-controls="collapseExample3">
            <div class="ms-auto me-4 pe-2">
                <div class="custom-control ios-switch ">
                    <input type="checkbox" class="ios-input" id="commission-toggle" v-on:change="paymentChange"
                        :checked="commission.payment_enabled == 'enabled'"
                        @input="commission.payment_enabled = $event.target.checked">
                    <label class="custom-control-label" for="commission-toggle"></label>
                </div>
            </div>
        </div>
    </div>
    <div class="card card-style bg-theme pb-0">
        <div class="content">
            <div>
                <label class="text-dark letterSpacing font-13 font-600 ms-2 text-capitalize ">
                    Order ID
                </label>
                <div class="letterSpacing py-2 form-control fs-6 font-600 mb-2 text-capitalize text-secondary"
                    style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);">
                    {{ commission.order ? commission.order.id : "-"}}
                </div>
            </div>
            <div>
                <label class="text-dark letterSpacing font-13 font-600 ms-2 text-capitalize ">
                    Partner
                </label>
                <router-link :to="{'name': 'partner-detail', params: {'id' : commission.partner.id}}"
                    class="letterSpacing py-2 form-control fs-6 font-600 mb-2 text-capitalize text-primary"
                    style="border:none; border-radius: 30px !important;background-color: rgb(226, 237, 255);">
                    {{ commission.partner ? commission.partner.full_name : "" }}
                </router-link>
            </div>
            <div v-if="commission.country">
                <label class="text-dark letterSpacing font-13 font-600 ms-2 text-capitalize ">
                    Commission ({{commission.country.currency_symbol}})
                </label>
                <div class="letterSpacing py-2 form-control fs-6 font-600 mb-2 text-capitalize text-success"
                    style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);">
                    {{commission.country.currency_symbol}} {{ commission.commission_amount }}
                </div>
            </div>
            <div>
                <label class="text-dark letterSpacing font-13 font-600 ms-2 text-capitalize ">
                    Commission (%)
                </label>
                <div class="letterSpacing py-2 form-control fs-6 font-600 mb-2 text-capitalize text-success"
                    style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);">
                    {{commission.commission_percentage}} %
                </div>
            </div>
            <div>
                <label class="text-dark letterSpacing font-13 font-600 ms-2 text-capitalize ">
                    Date
                </label>
                <div class="letterSpacing py-2 form-control fs-6 font-600 mb-2 text-capitalize text-secondary"
                    style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);">
                    {{ localDateTime(commission.created_at)}}
                </div>
            </div>
        </div>
    </div>
    <div class="ms-3 me-3 pt-3">
        <div class="commission-cardTitleContainer mb-3">
            <div class="commission-validationIconWrapper">
                <div class="commission-IconContainer">
                    <i class="fas fa-calendar-alt commission-cardIcon"></i>
                </div>
            </div>
            <h5 class="commission-cardTitle mb-0">
                Events
            </h5>
        </div>
        <div class="card card-style eventCard mx-1 rounded-s py-1 row mb-2 border-1 border-bottom"
            v-for="event in commission.events">
            <div class="letterSpacing font-14 font-500 primary-light-text">
                {{ event.description}}<br>
            </div>
            <div class="d-flex justify-content-between mt-1">
                <span class="letterSpacing">
                    {{ localDateTime(event.created_at) }}
                </span>
                <span class="letterSpacing primary-alt-base-text font-600" v-if="event.partner_full_name">By:
                    {{ event.partner_full_name ? event.partner_full_name : "" }}
                </span>
            </div>
        </div>
    </div>
</div>