import commissionService from "../../services";

export default {
    data() {
        return {
            commission: {
                partner: {},
                country: {}
            },
        }
    },
    mounted() {
        this.showLoader(true);
        commissionService.getCommission(this.$route.params.id).then(response => {
            this.hideLoader();
            this.commission = response.data.data;
        });
    },
    methods: {
        paymentChange() {
            this.showLoader(true);
            commissionService.changeCommissionPayment(this.$route.params.id).then(response => {
                this.hideLoader();
                this.$popper.success(response.data.message);
            });
        }
    }
}